<template>
    <form
        id="order-summary"
        class="h-full flex flex-col relative"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            form.resetValidation('general');
            form.resetValidation($event.target.name);
        "
    >
        <div class="flex flex-col p-8 h-full">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Your Cart
                </p>
            </div>
            <div class="relative mb-6">
                <h3
                    v-if="shownCartBoxes.length"
                    class="text-navy text-[28px]"
                >
                    Order summary
                </h3>
                <p
                    v-if="shownCartBoxes.length"
                    class="text-navy text-[14px]"
                >
                    Review your order
                </p>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>

            <div
                v-if="shownCartBoxes.length"
                :class="{ 'opacity-50': isLoading }"
                class="flex flex-col"
            >
                <div
                    v-for="(cartBox, index) in shownCartBoxes"
                    :key="index"
                    class="flex items-center mb-4"
                >
                    <img
                        class="bg-unknown-grey-800 rounded-lg w-[88px] flex-shrink-0 mr-4"
                        :src="cartBox.images.media.original"
                    >

                    <div class="mr-4">
                        <p class="text-navy text-[18px] w-[100px] truncate xs:w-[125px]">
                            {{ cartBox.name }}
                        </p>
                        <p class="text-navy text-[12px] w-[100px] truncate xs:w-[125px]">
                            ${{
                                readableDecimalNumber(cartBox.plans[0].price)
                            }}/m per box
                        </p>
                        <button
                            class="text-flushing-pink text-[12px] hover:text-bloody-nose"
                            :tabIndex="7"
                            data-message="Delete Button"
                            @click="removeFromCart(cartBox)"
                        >
                            Delete
                        </button>
                    </div>
                    <InputQuantity
                        :value="cartBox.quantity"
                        :decrease-tab-index="5"
                        :increase-tab-index="6"
                        class="ml-auto"
                        @click.prevent=""
                        @update:value="(quantity) => updateCartQuantity(cartBox, quantity, cartBox.quantity)"
                    />
                </div>
            </div>

            <div
                v-else
                class="flex flex-col items-center justify-center h-full"
            >
                <i class="icon icon-bag text-navy text-[54px] mb-8" />
                <p class="text-navy text-[14px] text-center">
                    Your cart is empty
                </p>
            </div>
        </div>

        <PromotionalCode
            v-if="shownCartBoxes.length && userBalance <= 0"
            @coupon-applied="handleCouponApplied"
            @coupon-removed="handleCouponRemoved"
            @refresh-credit="refreshPromotionalCreditBalance"
        />
        <div
            v-else-if="userBalance > 0"
            class="mb-4 px-6"
        >
            <div class="bg-[#75EFC8] p-5 rounded-[20px] mb-4">
                <p class="text-[#1F496E] font-bold">
                    ${{ userRedeemedAmount === null ? readableBalance(-userBalance)
                        : readableBalance(userRedeemedAmount) }} Credit applied
                </p>
            </div>
        </div>

        <BillingDetailsCard
            v-if="shownCartBoxes.length"
            title="Monthly total"
            description="No commitment, cancel anytime"
            :disabled="isLoading"
            :address="selectedDeliveryAddress.full_address"
            :price="totalPrice"
            :discounted-price="discountedTotalPrice"
            :card-last-four="selectedPaymentMethod.card_last_four"
            :is-card-brand-shown="true"
            :is-editable="true"
            :edit-address-tab-index="3"
            :edit-payment-tab-index="4"
        />

        <div class="flex flex-col items-center justify-center px-4">
            <div
                v-if="shownCartBoxes.length"
                class="flex w-full h-[65px] mb-2"
            >
                <button
                    v-if="!isLoading"
                    class="btn-primary h-full xs:w-[360px]"
                    :tabindex="1"
                    data-message="Place Order Button"
                    type="submit"
                >
                    Place Order
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>

            <button
                v-else
                class="btn-primary h-[65px] mb-2 xs:w-[360px] xs:mx-4"
                data-message="Add Boxes Button"
                @click="isProductListDrawerShown = true"
            >
                Add Boxes
            </button>
            <span class="text-center text-navy text-[12px] mb-4 hover:opacity-90">
                By confirming you agree to our
                <a
                    href="https://spacereimagined.io/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="font-medium"
                    :tabindex="2"
                    data-message="Terms & Conditions Link"
                >
                    Terms & Conditions
                </a>
            </span>
        </div>

        <Drawer
            :is-shown="isOrderPlacedDrawerShown"
            bg-class="bg-blue-noise"
            :is-back-shown="false"
            :is-close-shown="true"
            @close="SHOW_DRAWER(null)"
        >
            <OrderPlaced />
        </Drawer>
        <Drawer
            :is-shown="isProductListDrawerShown"
            bg-class="bg-blue-noise"
            :is-close-shown="false"
        >
            <ProductList />
        </Drawer>
    </form>
</template>

<script setup>
    import { ref, computed, reactive, onMounted, watch } from 'vue';
    import { mapGetters, mapMutations, mapActions } from 'root-js/store/lib.js';
    import {
        readableNumber,
        readableDecimalNumber
    } from 'root-js/helpers/number.helper';

    import { IS_SIMULATTION } from 'root-js/constants/system.const';

    import Drawer from 'root-js/components/common/Drawer.vue';
    import OrderPlaced from 'root-js/components/your-space/OrderPlaced.vue';
    import ProductList from 'root-js/components/your-space/ProductList.vue';
    import Form from 'root-js/utils/API/Form';
    import BillingDetailsCard from 'root-js/components/core/BillingDetailsCard.vue';
    import InputQuantity from 'root-js/components/common/InputQuantity.vue';
    import { useGtag } from 'vue-gtag-next';
    import * as Sentry from '@sentry/vue';
    import PromotionalCode from 'root-js/components/promotional-code/PromotionalCode.vue';
    import { readableBalance } from 'root-js/helpers/number.helper';

    const emits = defineEmits([ 'success' ]);

    const { selectedDeliveryAddress } = mapGetters('boxes');
    const { selectedPaymentMethod } = mapGetters('payments');
    const { cartBoxes } = mapGetters('store');
    const { SET_CART_PRODUCT } = mapMutations('store');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { GET_BOXES } = mapActions('boxes');
    const { user } = mapGetters('user');
    const { GET_USER } = mapActions('user');
    const { event } = useGtag();

    const isLoading = ref(false);
    const isOrderPlacedDrawerShown = ref(false);
    const isProductListDrawerShown = ref(false);
    const activePromotion = ref(null);
    const discountedTotalPrice = ref(null);
    const userBalance = ref(user.value.balance * -1);
    const userRedeemedAmount = ref(null);

    onMounted(() => {
        if (userBalance.value > 0) {
            calculateDiscountedTotalPrice();
        }
    });

    const form = reactive(new Form('create-order',
                                   {
                                       shipping_address: { value: selectedDeliveryAddress.value.id },
                                       billing_interval: { value: 'monthly' },
                                       items: {
                                           value: cartBoxes.value.map(cartBox => {
                                               return {
                                                   id: cartBox.id,
                                                   quantity: cartBox.quantity,
                                                   subscribe: true
                                               };
                                           })
                                       },
                                       payment_method: selectedPaymentMethod.value.id,
                                       promotion_code: { value: null },
                                       _simulate: { value: IS_SIMULATTION },
                                       _finalize: { value: true }

                                   },
                                   null,
                                   { success: () => emits('success') }));

    watch(() => selectedDeliveryAddress.value,
          () => {
              form.shipping_address = selectedDeliveryAddress.value.id;

          });


    watch(() => selectedPaymentMethod.value,
          () => {
              form.payment_method = selectedPaymentMethod.value.id;

          });


    watch(() => cartBoxes.value,
          () => {
              form.items = cartBoxes.value.map(cartBox => {
                  return {
                      id: cartBox.id,
                      quantity: cartBox.quantity,
                      subscribe: true
                  };
              });

          },
          { deep: true });

    function handleCouponApplied(couponData) {
        activePromotion.value = couponData;
        form.promotion_code = couponData.id;
        updateDiscountedPrice();
    }

    function handleCouponRemoved() {
        activePromotion.value = null;
        form.promotion_code = null;
        updateDiscountedPrice();

    }

    // Function to calculate discounted total price
    const calculateDiscountedTotalPrice = () => {
        discountedTotalPrice.value = userBalance.value > totalPrice.value ? totalPrice.value : userBalance.value;
    };

    const refreshPromotionalCreditBalance = data => {
        userBalance.value = data.customer_ending_balance * -1;
        userRedeemedAmount.value = data.amount_redeemed;
        calculateDiscountedTotalPrice();

    };

    function updateDiscountedPrice() {
        if (activePromotion.value) {
            const { amount_off, percent_off } = activePromotion.value;
            if (!amount_off) {
                discountedTotalPrice.value = totalPrice.value * (percent_off / 100);
            } else {
                discountedTotalPrice.value = Math.max(amount_off, 0);
            }
        } else {
            discountedTotalPrice.value = null;
        }
    }


    async function submit() {

        // Log the purchase started message to Sentry
        Sentry.captureMessage('Purchase was started');

        // Send GA event for order started
        event('purchase_started', {
            value: readableDecimalNumber(totalPrice.value),
            currency: 'USD'
        });
        isLoading.value = true;
        try {
            await form.submit(true);
            setTimeout(() => {
                GET_BOXES();
                GET_USER();
            }, 300);
            isOrderPlacedDrawerShown.value = true;

            // Log the purchase completion message to Sentry
            Sentry.captureMessage('Purchase was completed');

            // Send GA event for successful order placement
            event('purchase', {
                value: readableDecimalNumber(totalPrice.value),
                currency: 'USD'
            });


        } catch (error) {

        // Error intentionally ignored or handled elsewhere
        }

        isLoading.value = false;
    }
    const shownCartBoxes = computed(() => cartBoxes.value.filter(box => !!+box.quantity));

    const totalPrice = computed(() => cartBoxes.value.reduce((acc, cur) => {
        const total = cur.plans[0].price * cur.quantity;
        return acc + total;
    }, 0));

    function updateCartQuantity(cartBox, newQuantity, oldQuantity) {
        if (newQuantity > oldQuantity) {

            // Send GA event for adding product to cart
            event('add_to_cart', {
                currency: 'USD',
                value: readableDecimalNumber(cartBox.plans[0].price * (newQuantity - oldQuantity)),
                items: [ {
                    item_id: cartBox.id,
                    item_name: cartBox.name,
                    price: readableDecimalNumber(cartBox.plans[0].price),
                    quantity: newQuantity - oldQuantity
                } ]
            });
        } else if (newQuantity < oldQuantity) {

            // Send GA event for removing product from cart
            event('remove_from_cart', {
                currency: 'USD',
                value: readableDecimalNumber(cartBox.plans[0].price * (oldQuantity - newQuantity)),
                items: [ {
                    item_id: cartBox.id,
                    item_name: cartBox.name,
                    price: readableDecimalNumber(cartBox.plans[0].price),
                    quantity: oldQuantity - newQuantity
                } ]
            });
        }

        // Update the product quantity in the cart
        SET_CART_PRODUCT({ ...cartBox, quantity: newQuantity });

        userBalance.value > 0 ? calculateDiscountedTotalPrice() : updateDiscountedPrice();
    }
    function removeFromCart(cartBox) {

        // Send GA event for removing product from cart
        event('remove_from_cart', {
            currency: 'USD',
            value: readableDecimalNumber(cartBox.plans[0].price * cartBox.quantity),
            items: [ {
                item_id: cartBox.id,
                item_name: cartBox.name,
                price: readableDecimalNumber(cartBox.plans[0].price),
                quantity: cartBox.quantity
            } ]
        });

        // Set the product quantity to 0 to remove it from the cart
        SET_CART_PRODUCT({ ...cartBox, quantity: 0 });
    }
</script>
